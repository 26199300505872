import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';
import P from '../components/core/p';
import H3 from '../components/core/h3';
import SectionContainer from '../components/core/section-container';

const BlogPage = () => (
  <Layout>
    <Seo title="Tools" />
    <div>
      <P>The tools that I love.</P>
      <SectionContainer>
        <H3>Development</H3>
      </SectionContainer>
      <SectionContainer>
        <H3>Productivity</H3>
      </SectionContainer>
    </div>
  </Layout>
);

export default BlogPage;
